<template>
    <div>
        <div class="filters">
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\users\\list.filter_title') }}</v-toolbar-title>
            </v-toolbar>
            <v-row class="mx-0 mb-4">
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.phone"
                        :label="$t('src\\views\\users\\list.phone')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.firstname"
                        :label="$t('src\\views\\users\\list.firstname')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.lastname"
                        :label="$t('src\\views\\users\\list.lastname')"
                        hide-details
                    />
                </v-col>
                <v-col sm="3" class="py-0">
                    <v-text-field
                        v-model="filter.social"
                        :label="$t('src\\views\\users\\list.social')"
                        hide-details
                    />
                </v-col>
                <v-col sm="12" class="d-flex justify-end">
                    <v-btn
                        color="primary"
                        @click="filterApply">{{ $t('src\\views\\users\\list.filter_apply') }}</v-btn>
                    &nbsp;&nbsp;
                    <v-btn
                        color=""
                        @click="filterReset">{{ $t('src\\views\\users\\list.filter_reset') }}</v-btn>
                </v-col>
            </v-row>
        </div>
        <v-data-table
            :headers="headers"
            :items="entities"
            :server-items-length="entitiesCount"
            :items-per-page="itemsPerPage"
            class="users-list"
            :loading="loading"
            @pagination="paginate"
            :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
        >
            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>{{ $t('src\\views\\users\\list.2701') }}</v-toolbar-title>
                    <v-spacer />
                    <v-btn v-if="user.adminAccess && user.adminAccess.users >= ACCESS.WRITE" color="primary" @click="create">
                        <v-icon>mdi-plus</v-icon>{{ $t('src\\views\\users\\list.9255') }}</v-btn>
                </v-toolbar>
            </template>
            <template v-slot:[`item.createdAt`]="{ item }">
                {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
            </template>
            <template v-slot:[`item.phone`]="{ item }">
                <div class="link" @click="auth(item._id, item.phone)">
                    {{ item.phone }}
                    <svg
                        class="list-icon-primary"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24">
                        <path d="M18 16H14V18H18V20L21 17L18 14V16M11 4C8.8 4 7 5.8 7 8S8.8 12 11 12 15 10.2 15 8 13.2 4 11 4M11 14C6.6 14 3 15.8 3 18V20H12.5C12.2 19.2 12 18.4 12 17.5C12 16.3 12.3 15.2 12.9 14.1C12.3 14.1 11.7 14 11 14" />
                    </svg>
                </div>
            </template>
            <template v-slot:[`item.admin`]="{ item }">
                <v-icon v-if="item.admin" color="green">mdi-check</v-icon>
                <v-icon v-else color="red">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.blogger`]="{ item }">
                <v-icon v-if="item.blogger" color="green">mdi-check</v-icon>
                <v-icon v-else color="red">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.organizer`]="{ item }">
                <v-icon v-if="item.organizer" color="green">mdi-check</v-icon>
                <v-icon v-else color="red">mdi-close</v-icon>
            </template>
            <template v-slot:[`item.edit`]="{ item }">
                <v-icon v-if="user.adminAccess && user.adminAccess.users >= ACCESS.WRITE" color="primary" @click="edit(item._id)">mdi-pencil</v-icon>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { ACCESS } from '@/vars';
    import { generateCode } from '@/helpers';

    export default {
        name: 'UsersList',
        components: {
        },
        data: () => ({
            ACCESS,
            headers: [],
            page: 1,
            itemsPerPage: 10,
            loading: true,
            filter: {},
            filterApplied: {}
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('users', ['entities', 'entitiesCount']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            this.headers = [
                { text: this.$t('src\\views\\users\\list.createdAt'), value: 'createdAt', sortable: false },
                { text: this.$t('src\\views\\users\\list.phone'), value: 'phone', sortable: false },
                { text: this.$t('src\\views\\users\\list.firstname'), value: 'firstname', sortable: false },
                { text: this.$t('src\\views\\users\\list.lastname'), value: 'lastname', sortable: false },
                { text: this.$t('src\\views\\users\\list.social'), value: 'social', sortable: false },
                { text: this.$t('src\\views\\users\\list.blogger'), value: 'blogger', sortable: false },
                { text: this.$t('src\\views\\users\\list.organizer'), value: 'organizer', sortable: false },
                { text: this.$t('src\\views\\users\\list.admin'), value: 'admin', sortable: false },
            ];
            if(this.user && this.user.adminAccess && this.user.adminAccess.users >= ACCESS.WRITE) {
                this.headers.push({ text: '', align: 'right', value: 'edit' })
            }
        },
        methods: {
            async getUsers() {
                this.loading = true;
                await store.dispatch('users/fetch', { filter: this.filterApplied, page: this.page, itemsPerPage: this.itemsPerPage });
                this.loading = false;
            },
            async paginate({ page, itemsPerPage }) {
                this.page = page;
                this.itemsPerPage = itemsPerPage;
                await this.getUsers();
            },
            async filterApply() {
                this.filterApplied = this.filter;
                await this.getUsers();
            },
            async filterReset() {
                this.filterApplied = {};
                await this.getUsers();
            },
            async create() {
                await this.$router.push({ name: 'users-create' });
            },
            async edit(id) {
                await this.$router.push({ name: 'users-edit', params: { id } });
            },
            async auth(id, phone) {
                const code = generateCode(8);
                await store.dispatch('users/setAdminCode', { id, code });
                window.open(process.env.VUE_APP_FRONT_URL + `/?action=auth&phone=${phone}&code=${code}`, '_blank');
            }
        }
    }
</script>

<style lang="scss">
    .users-list {
        .link {
            display: flex;
            align-items: center;
            cursor: pointer;

            & > svg {
                margin-left: 5px;
            }
        }
    }
</style>
